import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Breadcrumb, Button } from "react-bootstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { showNotification } from "../../service/showNotification";
import AssetsTable from "../../components/AssetsTable";
import { uploadAssets, uploadDuplicateAssets } from "../../service/api";
import { ButtonLoader } from "../../components/ButtonLoader";
import { config } from "../../configs/index";
import useLocalStorage from "../../hooks/useLocalStorage";
import DuplicateAssetTable from "../../components/AssetsTable/DuplicateAssetTable";

const DuplicateAssetFoundModal = React.memo(
  ({
    acceptAllLoading,
    isOpen,
    onClose,
    onConfirm,
    duplicates,
    openRejectModal,
    handleReject,
  }) => {
    if (!isOpen) return null;
    return (
      <div className="overlay">
        <div
          className="overlayInnerContent "
          onClick={(e) => e.stopPropagation()}
        >
          <span className="closeBtn p-2" onClick={onClose}>
            &times;
          </span>
          <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
          >
            <div className="bg-white w-[90vw] max-w-lg p-3 rounded-lg max-h-[80vh]">
              <h3 className="text-xl font-semibold mb-4">Duplicates Found</h3>
              <p className="mb-4">
                There are assets with duplicate deal names. Do you want to add
                them as new?
              </p>
              <div className="duplicateTable">
                <DuplicateAssetTable
                  duplicate={duplicates}
                  onConfirm={onConfirm}
                  handleReject={handleReject}
                />
              </div>
              <div className="overlayButton mt-4">
                <Button
                  onClick={() => {
                    onConfirm(duplicates, false);
                  }}
                  className="bg-[#356C58] text-white px-4 py-2 rounded-lg hover:bg-[#2f563d]"
                  disabled={acceptAllLoading}
                >
                  {acceptAllLoading ? (
                    <ButtonLoader show={true} />
                  ) : (
                    "Accept All"
                  )}
                </Button>
                <Button
                  onClick={openRejectModal}
                  className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  Reject All
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const ConfirmationRejectModal = React.memo(
  ({ isRejectModalOpen, closeRejectModal, rejectAllAssets }) => {
    if (!isRejectModalOpen) return null;
    return (
      <div className="overlay">
        <div className="overlayInnerContent ">
          <span className="closeBtn p-2" onClick={closeRejectModal}>
            &times;
          </span>
          <div
            className={`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
          >
            <div className="bg-white w-[90vw] max-w-lg p-3 rounded-lg max-h-[80vh]">
              <h5 className="mb-4">
                Are Sure to <span className="warning">REJECT ALL</span> the
                Assets?
              </h5>
              <div className="overlayButton mt-4">
                <Button
                  onClick={rejectAllAssets}
                  className="bg-[#356C58] text-white px-4 py-2 rounded-lg hover:bg-[#2f563d]"
                >
                  YES
                </Button>
                <Button
                  onClick={closeRejectModal}
                  className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400"
                >
                  NO
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const AssetPage = () => {
  const fileInputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const [isNewUploaded, setIsNewUploaded] = useState(false);
  const [token, _] = useLocalStorage("token");
  const [searchTerm, setSearchTerm] = useState("");
  const [totalAssets, setTotalAssets] = useState();
  const [duplicates, setDuplicates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [acceptAllLoading, setAcceptAllLoader] = useState(false);
  const openAssetsConfirmModal = () => setIsModalOpen(true);
  const closeAssetsConfirmModal = () => {
    setDuplicates([]);
    setIsModalOpen(false);
  };
  const openRejectModal = () => setIsRejectModalOpen(true);
  const closeRejectModal = () => setIsRejectModalOpen(false);

  const rejectAllAssets = () => {
    setDuplicates([]);
    setIsRejectModalOpen(false);
    setIsModalOpen(false);
  };

  const handleReject = (id) => {
    const updatedAssets = duplicates.filter((asset) => asset.recordId !== id);
    setDuplicates(updatedAssets);
    if (updatedAssets.length === 0) {
      setIsModalOpen(false);
    }
  };

  const handleUpload = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const file = e.target?.files?.[0];

      const data = await uploadAssets(file);

      console.log(data.data.success);

      if (data.data.success) {
        showNotification("success", "Assets uploaded successfully");
      } else if (!data.success) {
        setDuplicates(data.data.duplicates);
        openAssetsConfirmModal();
      }
      setIsNewUploaded(!isNewUploaded);
    } catch (error) {
      console.log("error", error.response.data.message);
      showNotification("error", error?.response?.data?.message);
    } finally {
      setIsLoading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleAssetExport = async () => {
    setExportLoader(true);
    try {
      const response = await fetch(
        `${config.REACT_APP_BASE_URL}/api/admin/export-assets`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "Assets.xlsx";
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        throw new Error("Failed to generate the Excel file");
      }
    } catch (error) {
      console.error("Error during export:", error);
      showNotification("error", "Failed to download assets");
    } finally {
      setExportLoader(false);
    }
  };

  const handleAcceptAsset = async (duplicatesAsset, singleAsset) => {
    if (!singleAsset) {
      setAcceptAllLoader(true);
    }
    try {
      const data = await uploadDuplicateAssets(duplicatesAsset);
      if (data.data.success) {
        if (!singleAsset) {
          closeAssetsConfirmModal();
        } else {
          duplicatesAsset.map((duplicateAsset) => {
            const updatedAssets = duplicates.filter(
              (asset) => asset.recordId !== duplicateAsset.recordId
            );
            setDuplicates(updatedAssets);
            if (updatedAssets.length === 0) {
              setIsModalOpen(false);
            }
          });
        }
        showNotification("success", "Duplicates handled successfully");
      }
      setIsNewUploaded(!isNewUploaded);
    } catch (error) {
      showNotification("error", "Failed to handle duplicates");
    } finally {
      setAcceptAllLoader(false);
    }
  };

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb
            className="d-none d-md-inline-block "
            listProps={{
              className: "breadcrumb-dark breadcrumb-transparent ",
            }}
          >
            <Breadcrumb.Item>
              <FontAwesomeIcon icon={faHome} />
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Assets</Breadcrumb.Item>
          </Breadcrumb>
          <h4>
            Assets{" "}
            <span style={{ opacity: 0.7, fontSize: "1.2rem" }}>
              (Total Assets: {totalAssets})
            </span>
          </h4>
        </div>
        <div className="search-container">
          <input
            type="string"
            className="mx-2"
            placeholder="Deal Name or Asset ID"
            style={{ padding: "10px 8px" }}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <Button
            className="mx-2"
            onClick={handleAssetExport}
            disabled={exportLoader}
          >
            {exportLoader ? <ButtonLoader show={true} /> : "Export"}
          </Button>
          <Button
            className="mx-2"
            onClick={() => fileInputRef.current.click()}
            disabled={isLoading}
          >
            {isLoading ? <ButtonLoader show={true} /> : "Upload"}
          </Button>
          <input
            onChange={handleUpload}
            multiple={false}
            ref={fileInputRef}
            type="file"
            hidden
          />
        </div>
      </div>

      <AssetsTable
        isNewUploaded={isNewUploaded}
        searchTerm={searchTerm}
        setTotalAssets={setTotalAssets}
      />

      <DuplicateAssetFoundModal
        isOpen={isModalOpen}
        onClose={closeAssetsConfirmModal}
        openRejectModal={openRejectModal}
        duplicates={duplicates}
        onConfirm={handleAcceptAsset}
        handleReject={handleReject}
        acceptAllLoading={acceptAllLoading}
      />

      <ConfirmationRejectModal
        isRejectModalOpen={isRejectModalOpen}
        closeRejectModal={closeRejectModal}
        rejectAllAssets={rejectAllAssets}
      />
    </>
  );
};

export default AssetPage;
